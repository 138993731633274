const metroData = { 
    'Totals':['000s',15670,8036,2156,1716,4359,3677,5018,8292,5933,7696,7974,11414,2255,2422,908,3359,6299,7852,12522,12347,8581,13649,899,2220],
    '1':['000s',10164,5231,1117,854,2657,2573,3510,5503,4165,5147,5017,7667,1488,1637,610,2224,4247,5177,8191,8005,5700,8951,605,1556],
    '2':['000s',4582,2731,762,606,1539,1192,913,2397,1870,2466,2116,3247,800,846,316,1623,2000,2556,3738,3821,2444,4005,337,570],
    '3':['000s',4453,2593,604,434,1354,1239,1084,2294,1811,2262,2190,3201,678,734,287,1135,1892,2362,3602,3625,2369,3886,244,601],
    '4':['000s',2844,1774,394,305,965,808,588,1463,1183,1495,1349,2025,466,510,183,853,1222,1539,2290,2346,1512,2483,176,376],
    '1,2':['000s',11653,6090,1440,1125,3192,2898,3760,6227,4678,5882,5771,8653,1718,1886,708,2739,4832,5951,9381,9262,6456,10235,691,1708],
    '1,3':['000s',11713,6120,1398,1067,3179,2941,3834,6271,4711,5870,5842,8710,1712,1854,711,2622,4863,5962,9422,9270,6456,10284,687,1733],
    '1,4':['000s',11095,5801,1285,992,3008,2793,3679,5950,4495,5594,5501,8298,1631,1779,676,2513,4619,5651,8913,8772,6158,9752,659,1660],
    '2,3':['000s',7043,4157,1119,863,2310,1848,1497,3643,2853,3655,3388,4996,1164,1254,463,2098,3044,3832,5735,5811,3735,6143,466,885],
    '2,4':['000s',5962,3590,954,755,2027,1563,1205,3102,2446,3163,2799,4214,1016,1099,397,1915,2588,3281,4852,4934,3176,5201,416,750],
    '3,4':['000s',4964,2882,647,470,1483,1400,1254,2576,2022,2508,2455,3574,746,810,316,1218,2092,2600,3996,4017,2658,4329,277,685],
    '1,2,3':['000s',12545,6594,1615,1256,3492,3102,3942,6664,4973,6285,6260,9236,1850,2014,760,2909,5199,6404,10106,9982,6884,10991,739,1809],
    '1,2,4':['000s',12117,6363,1525,1194,3364,2999,3853,6450,4832,6103,6013,8955,1797,1964,736,2839,5025,6193,9746,9629,6684,10622,720,1761],
    '1,3,4':['000s',11872,6205,1419,1085,3222,2983,3882,6354,4769,5944,5928,8822,1737,1879,718,2646,4924,6044,9542,9390,6543,10416,697,1755],
    '2,3,4':['000s',7464,4388,1160,897,2421,1967,1638,3868,3032,3857,3606,5293,1222,1319,490,2158,3207,4023,6060,6127,3969,6501,493,955],
    '1,2,3,4':['000s',12680,6661,1636,1274,3530,3130,3983,6732,5024,6347,6333,9327,1874,2037,766,2926,5250,6474,10208,10080,6956,11101,749,1827],
    }
    const metroPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',64.86,65.09,51.81,49.77,60.95,69.98,69.95,66.37,70.2,66.88,62.92,67.17,65.99,67.59,67.18,66.21,67.42,65.93,65.41,64.83,66.43,65.58,67.3,70.09],
    '2':['Vert%',29.24,33.98,35.34,35.31,35.31,32.42,18.19,28.91,31.52,32.04,26.54,28.45,35.48,34.93,34.8,48.32,31.75,32.55,29.85,30.95,28.48,29.34,37.49,25.68],
    '3':['Vert%',28.42,32.27,28.01,25.29,31.06,33.7,21.6,27.67,30.52,29.39,27.46,28.04,30.07,30.31,31.61,33.79,30.04,30.08,28.77,29.36,27.61,28.47,27.14,27.07],
    '4':['Vert%',18.15,22.08,18.27,17.77,22.14,21.97,11.72,17.64,19.94,19.43,16.92,17.74,20.67,21.06,20.15,25.39,19.4,19.6,18.29,19,17.62,18.19,19.58,16.94],
    '1,2':['Vert%',74.37,75.78,66.79,65.56,73.23,78.81,74.93,75.1,78.85,76.43,72.37,75.81,76.19,77.87,77.97,81.54,76.71,75.79,74.92,75.01,75.24,74.99,76.86,76.94],
    '1,3':['Vert%',74.75,76.16,64.84,62.18,72.93,79.98,76.4,75.63,79.4,76.27,73.26,76.31,75.92,76.55,78.3,78.06,77.2,75.93,75.24,75.08,75.24,75.35,76.42,78.06],
    '1,4':['Vert%',70.8,72.19,59.6,57.81,69.01,75.96,73.32,71.76,75.76,72.69,68.99,72.7,72.33,73.45,74.45,74.81,73.33,71.97,71.18,71.05,71.76,71.45,73.3,74.77],
    '2,3':['Vert%',44.95,51.73,51.9,50.29,52.99,50.26,29.83,43.93,48.09,47.49,42.49,43.77,51.62,51.78,50.99,62.46,48.33,48.8,45.8,47.06,43.53,45.01,51.84,39.86],
    '2,4':['Vert%',38.05,44.67,44.25,44,46.5,42.51,24.01,37.41,41.23,41.1,35.1,36.92,45.06,45.38,43.72,57.01,41.09,41.79,38.75,39.96,37.01,38.11,46.27,33.78],
    '3,4':['Vert%',31.68,35.86,30.01,27.39,34.02,38.07,24.99,31.07,34.08,32.59,30.79,31.31,33.08,33.44,34.8,36.26,33.21,33.11,31.91,32.53,30.98,31.72,30.81,30.86],
    '1,2,3':['Vert%',80.06,82.06,74.91,73.19,80.11,84.36,78.56,80.37,83.82,81.67,78.51,80.92,82.04,83.15,83.7,86.6,82.54,81.56,80.71,80.85,80.22,80.53,82.2,81.49],
    '1,2,4':['Vert%',77.33,79.18,70.73,69.58,77.17,81.56,76.78,77.79,81.44,79.3,75.41,78.46,79.69,81.09,81.06,84.52,79.77,78.87,77.83,77.99,77.89,77.82,80.09,79.32],
    '1,3,4':['Vert%',75.76,77.22,65.82,63.23,73.92,81.13,77.36,76.63,80.38,77.23,74.34,77.29,77.03,77.58,79.07,78.77,78.17,76.97,76.2,76.05,76.25,76.31,77.53,79.05],
    '2,3,4':['Vert%',47.63,54.6,53.8,52.27,55.54,53.49,32.64,46.65,51.1,50.12,45.22,46.37,54.19,54.46,53.96,64.25,50.91,51.24,48.39,49.62,46.25,47.63,54.84,43.02],
    '1,2,3,4':['Vert%',80.92,82.89,75.88,74.24,80.98,85.12,79.37,81.19,84.68,82.47,79.42,81.72,83.1,84.1,84.36,87.11,83.35,82.45,81.52,81.64,81.06,81.33,83.31,82.3],
    }
    
    const nationalData = { 
    'Totals':['000s',22119,10939,2958,2338,5838,5101,7568,11787,8225,10886,11233,16388,2913,3253,1200,4294,8876,10960,17661,17073,12011,19118,1232,3292],
    '1':['000s',13954,7015,1551,1183,3531,3484,4955,7538,5615,7053,6901,10629,1911,2139,772,2803,5800,7042,11261,10849,7701,12182,826,2200],
    '2':['000s',6052,3539,993,786,1961,1577,1290,3145,2452,3264,2787,4367,993,1086,376,1954,2659,3330,4899,4933,3227,5246,433,810],
    '3':['000s',5981,3415,806,584,1759,1656,1536,3100,2389,3106,2876,4355,844,949,375,1366,2543,3145,4831,4753,3191,5187,350,836],
    '4':['000s',3670,2255,511,388,1218,1037,792,1887,1512,1968,1702,2649,571,626,243,1013,1585,1978,2952,2951,1961,3178,232,509],
    '1,2':['000s',15986,8154,1974,1538,4211,3943,5353,8542,6316,8065,7921,12013,2206,2475,894,3435,6623,8103,12881,12505,8747,13933,934,2430],
    '1,3':['000s',16128,8214,1930,1467,4211,4003,5477,8631,6361,8103,8025,12131,2199,2445,913,3299,6677,8139,12986,12560,8791,14036,953,2470],
    '1,4':['000s',15215,7761,1771,1359,3976,3785,5221,8155,6049,7685,7530,11506,2090,2324,872,3163,6317,7680,12236,11840,8340,13255,900,2354],
    '2,3':['000s',9518,5513,1487,1142,3000,2512,2161,4929,3826,5002,4516,6870,1457,1622,592,2565,4114,5105,7694,7677,5051,8239,629,1286],
    '2,4':['000s',7907,4677,1246,973,2601,2076,1702,4101,3218,4229,3678,5695,1265,1392,498,2322,3443,4292,6385,6402,4220,6845,542,1076],
    '3,4':['000s',6661,3784,871,634,1931,1853,1771,3463,2653,3463,3198,4858,932,1046,411,1467,2811,3464,5354,5261,3578,5771,394,962],
    '1,2,3':['000s',17291,8873,2213,1714,4624,4249,5655,9197,6749,8679,8612,12898,2378,2657,980,3657,7152,8755,13930,13525,9389,15029,1015,2592],
    '1,2,4':['000s',16647,8536,2086,1624,4445,4091,5504,8874,6533,8396,8251,12465,2305,2572,949,3567,6899,8435,13393,13011,9083,14483,976,2517],
    '1,3,4':['000s',16353,8330,1957,1490,4270,4059,5554,8751,6433,8217,8136,12293,2232,2476,927,3333,6765,8251,13153,12724,8918,14218,966,2509],
    '2,3,4':['000s',10066,5810,1544,1187,3149,2661,2345,5217,4049,5286,4780,7267,1535,1704,623,2639,4328,5354,8118,8086,5358,8706,667,1388],
    '1,2,3,4':['000s',17480,8966,2237,1735,4677,4289,5721,9296,6813,8776,8704,13032,2410,2686,990,3684,7226,8848,14069,13660,9496,15182,1028,2625],
    }
    const nationalPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',63.09,64.13,52.43,50.6,60.48,68.3,65.47,63.95,68.27,64.79,61.44,64.86,65.6,65.75,64.33,65.28,65.34,64.25,63.76,63.54,64.12,63.72,67.05,66.83],
    '2':['Vert%',27.36,32.35,33.57,33.62,33.59,30.92,17.05,26.68,29.81,29.98,24.81,26.65,34.09,33.38,31.33,45.51,29.96,30.38,27.74,28.89,26.87,27.44,35.15,24.61],
    '3':['Vert%',27.04,31.22,27.25,24.98,30.13,32.46,20.3,26.3,29.05,28.53,25.6,26.57,28.97,29.17,31.25,31.81,28.65,28.7,27.35,27.84,26.57,27.13,28.41,25.39],
    '4':['Vert%',16.59,20.61,17.28,16.6,20.86,20.33,10.47,16.01,18.38,18.08,15.15,16.16,19.6,19.24,20.25,23.59,17.86,18.05,16.71,17.28,16.33,16.62,18.83,15.46],
    '1,2':['Vert%',72.27,74.54,66.73,65.78,72.13,77.3,70.73,72.47,76.79,74.09,70.52,73.3,75.73,76.08,74.5,80,74.62,73.93,72.93,73.24,72.82,72.88,75.81,73.82],
    '1,3':['Vert%',72.91,75.09,65.25,62.75,72.13,78.47,72.37,73.22,77.34,74.44,71.44,74.02,75.49,75.16,76.08,76.83,75.23,74.26,73.53,73.57,73.19,73.42,77.35,75.03],
    '1,4':['Vert%',68.79,70.95,59.87,58.13,68.11,74.2,68.99,69.19,73.54,70.6,67.03,70.21,71.75,71.44,72.67,73.66,71.17,70.07,69.28,69.35,69.44,69.33,73.05,71.51],
    '2,3':['Vert%',43.03,50.4,50.27,48.85,51.39,49.25,28.55,41.82,46.52,45.95,40.2,41.92,50.02,49.86,49.33,59.73,46.35,46.58,43.56,44.97,42.05,43.1,51.06,39.06],
    '2,4':['Vert%',35.75,42.76,42.12,41.62,44.55,40.7,22.49,34.79,39.12,38.85,32.74,34.75,43.43,42.79,41.5,54.08,38.79,39.16,36.15,37.5,35.13,35.8,43.99,32.69],
    '3,4':['Vert%',30.11,34.59,29.45,27.12,33.08,36.33,23.4,29.38,32.26,31.81,28.47,29.64,31.99,32.15,34.25,34.16,31.67,31.61,30.32,30.81,29.79,30.19,31.98,29.22],
    '1,2,3':['Vert%',78.17,81.11,74.81,73.31,79.21,83.3,74.72,78.03,82.05,79.73,76.67,78.7,81.63,81.68,81.67,85.17,80.58,79.88,78.87,79.22,78.17,78.61,82.39,78.74],
    '1,2,4':['Vert%',75.26,78.03,70.52,69.46,76.14,80.2,72.73,75.29,79.43,77.13,73.45,76.06,79.13,79.07,79.08,83.07,77.73,76.96,75.83,76.21,75.62,75.76,79.22,76.46],
    '1,3,4':['Vert%',73.93,76.15,66.16,63.73,73.14,79.57,73.39,74.24,78.21,75.48,72.43,75.01,76.62,76.11,77.25,77.62,76.22,75.28,74.47,74.53,74.25,74.37,78.41,76.22],
    '2,3,4':['Vert%',45.51,53.11,52.2,50.77,53.94,52.17,30.99,44.26,49.23,48.56,42.55,44.34,52.69,52.38,51.92,61.46,48.76,48.85,45.97,47.36,44.61,45.54,54.14,42.16],
    '1,2,3,4':['Vert%',79.03,81.96,75.63,74.21,80.11,84.08,75.59,78.87,82.83,80.62,77.49,79.52,82.73,82.57,82.5,85.79,81.41,80.73,79.66,80.01,79.06,79.41,83.44,79.74],
    }
    
    const regionalData = { 
    'Totals':['000s',6449,2903,802,622,1479,1424,2550,3495,2292,3190,3259,4974,658,831,293,935,2577,3108,5139,4726,3430,5469,333,1072],
    '1':['000s',3790,1785,435,329,874,911,1446,2035,1449,1905,1884,2962,422,503,162,579,1552,1865,3070,2844,2001,3231,221,644],
    '2':['000s',1470,808,231,181,423,385,377,748,582,798,672,1120,194,240,61,331,659,774,1161,1112,783,1241,96,240],
    '3':['000s',1529,822,202,149,405,417,452,806,578,843,685,1154,165,215,88,231,651,784,1228,1128,822,1301,105,235],
    '4':['000s',826,481,116,83,253,228,204,424,329,473,353,625,105,116,60,160,364,439,662,604,450,696,56,133],
    '1,2':['000s',4333,2064,534,413,1019,1044,1593,2315,1637,2183,2150,3360,487,589,186,696,1791,2153,3499,3243,2292,3698,243,722],
    '1,3':['000s',4415,2095,533,400,1032,1062,1644,2359,1650,2233,2182,3421,487,591,203,676,1813,2177,3564,3290,2335,3752,266,736],
    '1,4':['000s',4120,1960,486,366,967,992,1542,2205,1554,2091,2029,3208,460,545,196,650,1698,2029,3323,3067,2182,3504,241,695],
    '2,3':['000s',2475,1355,368,279,690,665,664,1287,973,1347,1128,1874,293,368,128,466,1070,1274,1959,1866,1316,2096,163,401],
    '2,4':['000s',1945,1086,292,218,574,512,496,999,772,1066,879,1480,249,293,101,407,856,1011,1533,1467,1044,1644,126,325],
    '3,4':['000s',1697,902,224,164,448,454,516,887,631,954,743,1284,186,235,95,249,720,864,1358,1244,919,1442,117,276],
    '1,2,3':['000s',4746,2279,598,458,1133,1147,1713,2533,1776,2394,2352,3662,528,644,220,748,1953,2351,3824,3544,2506,4038,276,783],
    '1,2,4':['000s',4530,2173,561,429,1081,1092,1651,2424,1701,2293,2237,3509,508,608,214,727,1874,2242,3647,3382,2399,3861,255,756],
    '1,3,4':['000s',4481,2125,538,406,1049,1076,1672,2397,1664,2273,2208,3471,495,597,208,686,1841,2207,3611,3334,2375,3802,269,753],
    '2,3,4':['000s',2603,1422,384,289,728,694,707,1349,1017,1429,1174,1974,313,385,133,481,1121,1331,2058,1959,1390,2205,174,433],
    '1,2,3,4':['000s',4800,2306,601,461,1146,1159,1737,2564,1789,2430,2371,3705,535,649,224,758,1976,2375,3861,3580,2540,4082,279,799],
    }
    const regionalPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',58.77,61.49,54.24,52.89,59.09,63.97,56.71,58.23,63.22,59.72,57.81,59.55,64.13,60.53,55.29,61.93,60.23,60.01,59.74,60.18,58.34,59.08,66.37,60.07],
    '2':['Vert%',22.79,27.83,28.8,29.1,28.6,27.04,14.78,21.4,25.39,25.02,20.62,22.52,29.48,28.88,20.82,35.4,25.57,24.9,22.59,23.53,22.83,22.69,28.83,22.39],
    '3':['Vert%',23.71,28.32,25.19,23.95,27.38,29.28,17.73,23.06,25.22,26.43,21.02,23.2,25.08,25.87,30.03,24.71,25.26,25.23,23.9,23.87,23.97,23.79,31.53,21.92],
    '4':['Vert%',12.81,16.57,14.46,13.34,17.11,16.01,8,12.13,14.35,14.83,10.83,12.57,15.96,13.96,20.48,17.11,14.12,14.12,12.88,12.78,13.12,12.73,16.82,12.41],
    '1,2':['Vert%',67.19,71.1,66.58,66.4,68.9,73.31,62.47,66.24,71.42,68.43,65.97,67.55,74.01,70.88,63.48,74.44,69.5,69.27,68.09,68.62,66.82,67.62,72.97,67.35],
    '1,3':['Vert%',68.46,72.17,66.46,64.31,69.78,74.58,64.47,67.5,71.99,70,66.95,68.78,74.01,71.12,69.28,72.3,70.35,70.05,69.35,69.61,68.08,68.6,79.88,68.66],
    '1,4':['Vert%',63.89,67.52,60.6,58.84,65.38,69.66,60.47,63.09,67.8,65.55,62.26,64.5,69.91,65.58,66.89,69.52,65.89,65.28,64.66,64.9,63.62,64.07,72.37,64.83],
    '2,3':['Vert%',38.38,46.68,45.89,44.86,46.65,46.7,26.04,36.82,42.45,42.23,34.61,37.68,44.53,44.28,43.69,49.84,41.52,40.99,38.12,39.48,38.37,38.33,48.95,37.41],
    '2,4':['Vert%',30.16,37.41,36.41,35.05,38.81,35.96,19.45,28.58,33.68,33.42,26.97,29.75,37.84,35.26,34.47,43.53,33.22,32.53,29.83,31.04,30.44,30.06,37.84,30.32],
    '3,4':['Vert%',26.31,31.07,27.93,26.37,30.29,31.88,20.24,25.38,27.53,29.91,22.8,25.81,28.27,28.28,32.42,26.63,27.94,27.8,26.43,26.32,26.79,26.37,35.14,25.75],
    '1,2,3':['Vert%',73.59,78.5,74.56,73.63,76.61,80.55,67.18,72.47,77.49,75.05,72.17,73.62,80.24,77.5,75.09,80,75.79,75.64,74.41,74.99,73.06,73.83,82.88,73.04],
    '1,2,4':['Vert%',70.24,74.85,69.95,68.97,73.09,76.69,64.75,69.36,74.21,71.88,68.64,70.55,77.2,73.16,73.04,77.75,72.72,72.14,70.97,71.56,69.94,70.6,76.58,70.52],
    '1,3,4':['Vert%',69.48,73.2,67.08,65.27,70.93,75.56,65.57,68.58,72.6,71.25,67.75,69.78,75.23,71.84,70.99,73.37,71.44,71.01,70.27,70.55,69.24,69.52,80.78,70.24],
    '2,3,4':['Vert%',40.36,48.98,47.88,46.46,49.22,48.74,27.73,38.6,44.37,44.8,36.02,39.69,47.57,46.33,45.39,51.44,43.5,42.82,40.05,41.45,40.52,40.32,52.25,40.39],
    '1,2,3,4':['Vert%',74.43,79.44,74.94,74.12,77.48,81.39,68.12,73.36,78.05,76.18,72.75,74.49,81.31,78.1,76.45,81.07,76.68,76.42,75.13,75.75,74.05,74.64,83.78,74.53],
    }

const graph_source = 'Nielsen CMV, National Survey 5 2024'

const exportedObject = {
    metroData,
    metroPercentage,
    nationalData,
    nationalPercentage,
    regionalData,
    regionalPercentage,
    graph_source

}

export default exportedObject


    